<template>
  <RaiSetups v-model="activeSetupQuery" :loading="loading">
    <TemplateSetup
      :store="store"
      :type="completedSetups[0]"
      :messages="templateMessages"
      @add="onTemplateAdd"
      @edit="onTemplateEdit"
      @next="onNext"
    />
    <AutoResponseSetup
      :type="completedSetups[1]"
      :messages="autoReplyMessages"
      @add="onAutoResponseAdd"
      @edit="onAutoResponseEdit"
      @next="onNext"
    />
    <InfoSetup
      :store="store"
      :type="completedSetups[2]"
      @edit-landline-number="onLandlineNumberEdit"
      @next="onNext"
    />
  </RaiSetups>
</template>

<script>
import { RaiSetups, SetupType } from "@/core-ui";
import { hasSnackbarAccess } from "@/mixins/ui";
import { useRouterQuery } from "@/mixins/routerQuery";
import { TemplateSetup, AutoResponseSetup, InfoSetup } from "./components";
import {
  STORE_SMS_QUERY,
  STORE_LANDLINE_NUMBER_UPDATE,
  STORE_MESSAGES_QUERY,
} from "./graphql";

export default {
  name: "TextingSetupView",
  components: {
    RaiSetups,
    TemplateSetup,
    AutoResponseSetup,
    InfoSetup,
  },
  mixins: [
    useRouterQuery({ name: "activeSetup", initialValue: 0 }),
    hasSnackbarAccess,
  ],
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
    raiLinkTo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    store: {},
    messages: [],
    completedSetups: new Array(3),
  }),
  apollo: {
    store: {
      query: STORE_SMS_QUERY,
      variables() {
        return { storeId: this.storeId };
      },
    },
    messages: {
      query: STORE_MESSAGES_QUERY,
      watchLoading(loading) {
        this.setLoading(loading);
      },
      variables() {
        return { storeId: this.storeId };
      },
    },
  },
  computed: {
    templateMessages() {
      return this.messages.filter((x) => x.isAutoReply === false);
    },
    autoReplyMessages() {
      return this.messages.filter((x) => x.isAutoReply === true);
    },
  },
  methods: {
    setLoading(isLoading) {
      this.loading = isLoading;
    },
    onNext() {
      this.completedSetups[this.activeSetupQuery] = SetupType.COMPLETE;
      this.activeSetupQuery++;
    },
    onTemplateAdd() {
      this.$router.push({
        name: this.raiLinkTo.onTemplateAdd,
        preserveQuery: true,
      });
    },
    onTemplateEdit(item) {
      this.$router.push({
        name: this.raiLinkTo.onTemplateEdit,
        params: { messageId: parseInt(item.id) },
        preserveQuery: true,
      });
    },
    onAutoResponseAdd() {
      this.$router.push({
        name: this.raiLinkTo.onAutoResponseAdd,
        preserveQuery: true,
      });
    },
    onAutoResponseEdit(item) {
      this.$router.push({
        name: this.raiLinkTo.onAutoResponseEdit,
        params: { messageId: parseInt(item.id) },
        preserveQuery: true,
      });
    },
    async onLandlineNumberEdit({ landlineNumber, resolve }) {
      const { data } = await this.$apollo.mutate({
        mutation: STORE_LANDLINE_NUMBER_UPDATE,
        variables: { number: landlineNumber },
      });

      const { errors = [] } = data.storeUpdateLandlineNumber;

      if (errors.length > 0) {
        this.showSnackbar({
          text: `Error updating store main number: ${errors.join("; ")}`,
        });
      } else {
        await this.$apollo.queries.store.refetch();
        resolve();
      }
    },
  },
};
</script>
